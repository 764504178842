import uniqueId from "lodash/uniqueId";
import { ChangeEvent, forwardRef, KeyboardEvent } from "react";

import { classnames } from "@/js/utils/cambio";

interface TextAreaProps {
  disabled?: boolean;
  onBlur?: (evt: ChangeEvent<HTMLTextAreaElement>) => void;
  onChange: (input: string) => void;
  onFocus?: (evt: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (evt: KeyboardEvent<HTMLTextAreaElement>) => void;
  invalid?: boolean;
  name?: string;
  rows?: number;
  maxLength?: number;
  id?: string;
  placeholder?: string;
  value?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      disabled,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      invalid,
      name = "",
      rows = 5,
      maxLength = 2000,
      id = `${name}-${uniqueId()}`,
      placeholder,
      value = "",
    }: TextAreaProps,
    ref,
  ) => {
    return (
      <textarea
        ref={ref}
        disabled={disabled}
        name={name}
        rows={rows}
        maxLength={maxLength}
        id={id}
        className={classnames("TextArea", { invalid })}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
    );
  },
);

export default TextArea;
