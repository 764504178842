import { forwardRef } from "react";

import { classnames } from "@/js/utils/cambio";

type Option = {
  value: string;
  label: string;
};

interface RadioGroupProps {
  /** Whether to render all radio buttons as disabled */
  disabled?: boolean;
  /** Name for the overall radiogroup fieldset, not an individual radio */
  label?: string;
  /** All radio buttons that belong to the same group should have the same name */
  name?: string;
  /**
   * This should be true for an input that fails validation. It will have a red border. I think the
   * only case for this would be if we don't provide a default and it is a required field.
   */
  invalid?: boolean;
  /** Callback to selected a radio button */
  onChange: (input: string) => void;
  /** List of possible items to select */
  options: Option[];
  /** Current 'checked' radio button */
  value?: string;
}

/**
 * A simple radio group component for our forms.
 */
const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  ({ disabled, name, onChange, options, invalid, label, value }, ref) => (
    <fieldset className={classnames("RadioGroup", { invalid })} disabled={disabled} name={label}>
      {options.map((option) => (
        <p key={option.label}>
          <input
            ref={ref}
            checked={value === option.value}
            disabled={disabled}
            id={option.label}
            name={name}
            onChange={disabled ? null : () => onChange(option.value)}
            type="radio"
            value={option.value}
          />
          <label htmlFor={option.label}>{option.label}</label>
        </p>
      ))}
    </fieldset>
  ),
);

export default RadioGroup;
