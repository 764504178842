import type { PropsWithChildren } from "react";
import type { FormFieldHandle, FormFieldProps } from "./FormField";

import noop from "lodash/noop";
import {
  createContext,
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import Button, { ButtonProps } from "@/components/Button";
import Spinner from "@/components/Spinner";

import FormFieldBase from "./FormField";

interface FormContext {
  /**
   * This must be passed to a FormField wrapper component as its forwardRef. It attaches its
   * imperative handle to a map ref on the Form, which is how the Form keeps track of all its
   * FormField children without any constraints on where in the DOM they might be.
   */
  attachFormFieldRef: (name: string) => (ref: FormFieldHandle) => void;
  /** Keeps track of which form fields have been blurred, also for a FormField showing validation */
  setBlurredFields: (fields: string[]) => void;
  blurredFields: string[];
  /** Whether or not the user has at least once tried to submit the form (and failed validation) */
  hasAttemptedSubmit: boolean;
}

export const FormContext = createContext<FormContext | null>(null);

type FormHandle = {
  submit: () => void;
  validate: () => boolean;
};

interface FormProps {
  /** If true, the Form won't submit when the button is clicked or the Enter key is pressed */
  disabled?: boolean;
  /** If the SubmitButton is not nested within the form, it can still link to the form with this */
  id?: string;
  /** When true, the Form will show an Overlay Spinner */
  isSubmitting?: boolean;
  /** Callback to form submission */
  onSubmit: () => void;
}

/**
 * A generic form component that keeps track of its FormField children through the FormContext.
 *
 * It handles a few things, notably:
 *  * When to show and hide validation errors
 *  * Consistent styling and loading states
 *  * Submitting the form
 *
 * When using Form, all of the form elements should be wrapped in a FormField:
 *
 * ```
 * <Form>
 *   <FormField name={...} value={...}><Input onChange={...}/></FormField>
 *   <FormField label={...} name={...} value={...}><Select onChange={...}/></FormField>
 *   {...}
 *   <SubmitButton />
 * </Form>
 * ```
 *
 * Each row of fields can be wrapped in a `<FormFieldRow />` for a nice grid-spaced form.
 */
const Form = forwardRef<FormHandle, PropsWithChildren<FormProps>>(
  ({ id, onSubmit = noop, children, ...props }, ref) => {
    /**
     * We show error messages in two instances: (1) if the user blurs an input field they started
     * typing in, and (2) if they have attempted a form submission that has then failed. The
     * following states handle the two states, respectively.
     */
    const [blurredFields, setBlurredFields] = useState<string[]>([]);
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
    // Each FormField within the Form will register itself in this ref
    const formFieldsRef = useRef<{ [name: string]: FormFieldHandle }>({});

    /**
     * Goes through the list of fields and gets their list of error messages. If there are
     * none, we have a successful validation. If there are some, it finds the first input
     * field that it can focus and auto-focuses. So this function also has a side effect.
     */
    const validate = (): boolean => {
      const errorsByField = Object.keys(formFieldsRef.current).reduce(
        (memo, name) => {
          const fieldErrors = formFieldsRef.current[name]?.validate();

          return Object.assign(memo, fieldErrors.length ? { [name]: fieldErrors } : memo);
        },
        {} as Record<keyof typeof formFieldsRef.current, string[]>,
      );

      if (!Object.keys(errorsByField).length) {
        return true;
      }

      const firstFocusableField = Object.keys(errorsByField).find(
        (name) => formFieldsRef.current[name]?.focus,
      );

      setHasAttemptedSubmit(true);
      formFieldsRef.current[firstFocusableField]?.focus();

      return false;
    };

    /**
     * Allows any parent component to submit or validate the form.
     */
    useImperativeHandle(ref, () => ({
      submit: onSubmit,
      validate,
    }));

    return (
      <FormContext.Provider
        value={{
          blurredFields,
          setBlurredFields,
          attachFormFieldRef: (name: string) => (ref: FormFieldHandle) => {
            ref ? (formFieldsRef.current[name] = ref) : delete formFieldsRef.current[name];
          },
          hasAttemptedSubmit,
        }}
      >
        <form
          id={id}
          className="Form"
          noValidate
          onSubmit={(evt) => {
            evt.preventDefault();

            if (!props.disabled && validate()) {
              onSubmit();
            }
          }}
        >
          {props.isSubmitting ?
            <Spinner flavor="overlay" />
          : null}
          {children}
        </form>
      </FormContext.Provider>
    );
  },
);

export default Form;

/**
 * This wrapper around the FormFieldBase is simply to pass the attachFormFieldRef as its forwarded
 * ref. I tried to shim this in directly to the imperative ref with bad results. The upshot is that
 * this is the component that must be used when using a Form.
 */
export function FormField(props: FormFieldProps) {
  const { attachFormFieldRef, blurredFields, setBlurredFields, hasAttemptedSubmit } =
    useContext(FormContext);

  return (
    <FormFieldBase
      ref={attachFormFieldRef(props.name)}
      onBlur={() => setBlurredFields([...new Set(blurredFields.concat(props.name))])}
      shouldValidate={hasAttemptedSubmit || (props.value && blurredFields.includes(props.name))}
      {...props}
    />
  );
}

interface SubmitButtonProps extends ButtonProps {
  form?: string;
  children?: string;
}

/**
 * Simple convenience wrapper that can be exported with the rest of the form elements.
 */
export function SubmitButton({ children = "Submit", ...props }: SubmitButtonProps) {
  return (
    <Button flavor="primary" form={props.form} type="submit" {...props}>
      {children}
    </Button>
  );
}

// add more form components here as we get them
export { default as DatePicker } from "@/components/DatePicker";
export { default as Input } from "@/components/Input";
export { default as Select } from "@/components/Select";
export { default as RadioGroup } from "@/components/RadioGroup";
export { default as SearchSelect } from "@/components/Select/SearchSelect";
export { default as TextArea } from "@/components/TextArea";
export { default as FormFieldRow } from "./FormFieldRow";
export * as Validators from "@/js/utils/validators";
