import partition from "lodash/partition";
import sum from "lodash/sum";
import { Children, cloneElement, ReactElement, type PropsWithChildren } from "react";

const GRID_COLUMNS = 12;

/**
 * A nice way to render multiple form fields in a row. This component is display: grid and will
 * look for the `gridWidth` prop of any of its children and calculate appropriate implied
 * grid widths to any children that don't.
 */
export default function FormFieldRow(props: PropsWithChildren) {
  const children = Children.toArray(props.children).filter(
    (child) => typeof child === "object" && "props" in child,
  ) as ReactElement[];
  const [childrenWithGridWidth, childrenWithoutGridWidth] = partition(
    children,
    (child) => !!child.props.gridWidth,
  );
  const implicitGridWidth = Math.floor(
    (GRID_COLUMNS -
      sum((childrenWithGridWidth as ReactElement[]).map((child) => child.props.gridWidth))) /
      childrenWithoutGridWidth.length,
  );

  return (
    <div className="FormFieldRow">
      {children.map((child) => {
        return cloneElement(child, {
          gridWidth: undefined,
          style: { gridColumn: `span ${child.props.gridWidth || implicitGridWidth}` },
        });
      })}
    </div>
  );
}
